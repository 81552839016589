import PropTypes from "prop-types";
import ReactECharts from "echarts-for-react";
import { CircularProgress, Skeleton, Stack, Typography } from "@mui/material";

import useQuery from "../../../hooks/useQuery";
import largeNumFormat from '../../../utils/largeNumFormat';
function BarChart({ id, style }) {
  const userLocale = navigator.language;
  // *--- Looker data ---*
  const { data, fields, isLoaded, title } = useQuery({ id }) || {};

  if (!isLoaded) {
    return (
      <Stack gap="16px">
        <Skeleton variant="lightText" width="80%" height="24px" />
        <Stack alignItems="center" justifyContent="center" height="100%" style={style}>
          <CircularProgress />
        </Stack>
      </Stack>
    );
  }

  const labels = Object.keys(data[0]).map((key, index) => {
    const formattedLabel = fields.measures[index].label_short.replace(/^sum of /i, '');
    return {
      name: key,
      label: formattedLabel,
    };
  });

  const serieData = labels.map((label) => {
    const formattedLabel = largeNumFormat(data[0][label.name], userLocale)
    return {
      label: formattedLabel,
      value: data[0][label.name],
    };
  });
  // *--- end of Looker data ---*

  //*--- Static Content ---*
  // const title = "World share of energy consumption, 2021";
  // const labels = [{ label: "Oil" }, { label: "Coal" }, { label: "Gas" }, { label: "Hydropower" }, { label: "Nuclear" }, { label: "Wind" }, { label: "Solar" }];
  // const serieData = [68954, 48212, 41032, 12755, 5000, 3050, 918];
  //*--- end of Static Content ---*

  const serie = {
    type: "bar",
    barMinHeight: "24px",
    barCategoryGap: "4px",

    label: {
      show: true,
      position: "outside",
      fontSize: "10px",
      color: "white",
      fontFamily: "Barlow",
      fontWeight: "500",
      formatter: (params) => params.data.label,
    },
    itemStyle: {
      color: function (params) {
        return colors[params.dataIndex];
      },
    },
    data: serieData,
  };

  const colors = ["#6A6158", "#B08C55", "#D9B683", "#2D428F", "#566AB6", "#6798B4", "#50B2B2"];

  const option = {
    grid: {
      left: 0,
      right: "3%",
      top: 0,
      bottom: 0,
      containLabel: true,
    },
    xAxis: {
      name: "amount",
      show: false,
      axisLabel: { show: false },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      inverse: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: "10px",
        color: "rgba(255, 255, 255, 0.8)",
        fontFamily: "Barlow",
        fontWeight: "500",
        formatter: function (params) {
          return params.toUpperCase();
        },
      },
      data: labels.map((item) => item.label),
    },
    series: [serie],
  };

  return (
    <Stack gap="16px">
      <Typography variant="h4" color="white">
        {title}
      </Typography>
      <ReactECharts option={option} style={style} lazyUpdate />
    </Stack>
  );
}

BarChart.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
};

export default BarChart;
