import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import ModalContent from './ModalContent';
export default function ModalHubSpot() {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const formSubmitted = localStorage.getItem('hsFormSentLooker');

    if (!formSubmitted) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [setOpen]);

  return (
      <Modal
        open={open}
        disableEscapeKeyDown
        className={open ? 'show' : 'hide'}
        BackdropProps={{
          style: {
            backgroundColor: "#fff",
            opacity: "0.75",
          },
        }}
      >
        <>
          {open && (<ModalContent setOpen={setOpen} open={open} />)}
        </>
      </Modal>
    )
}