import { useEffect, useState } from 'react';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { Box } from '@mui/material';

const ModalContent = ({setOpen, open}) => {

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: '20041319',
    formId: '7e6ee766-c218-4c90-98a5-46ee524a0d63',
    target: '#hubspotFormModal',
    onFormSubmitted: (() => { handleSubmit() })
  });

  

  const style = {
    borderRadius: "12px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '400px',
    maxWidth: '90vw',
    bgcolor: "white",
    boxShadow:
      "0px 1px 2px 1px rgba(39, 57, 57, 0.1), 0px 4px 16px rgba(39, 57, 57, 0.4)",
    '&:focus-visible': {
      outline: 'none',
    }
  };

  const handleSubmit = () => {
    localStorage.setItem('hsFormSentLooker', true);
    handleClose();
  }

  const handleClose = () => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 2000);
    return () => clearTimeout(timer);
  };
  return (
    <Box sx={style}>
      <div
        className="modalHubSpotWrapper firstModal" style={{display: 'relative'}}>
          <div className="bodyModalHubSpot">
            <div className="modalText">
              <h1 style={{fontSize: '15px', fontWeight: 600, marginTop: 0}}>
                Please provide a valid commercial email to continue
              </h1>
            </div>
            <div className="hubSpotWrapper" id="hubspotFormModal" />
          </div>
      </div>
    </Box>
  )
}

export default ModalContent;