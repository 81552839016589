import { Stack, Typography } from "@mui/material";

import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';

import Card from "./components/Card";
import BarChart from "../../components/Visualization/BarChart";
import RadarChart from "../../components/Visualization/RadarChart";

import { Grid, Container } from "./styled";

import cards from "../cards.json";
import ModalHubSpot from '../../components/ModalHubSpot';
import { useState } from 'react';
import { useDashboard } from '../../context/Dashboard';

function EnergySources() {
  const { isLoading } = useDashboard();

  return (
    <Stack sx={{ padding: { xs: "20px", md: "0 102px 25px 102px" } }}>
      {!isLoading && (
        <HubspotProvider>
          <ModalHubSpot /> 
        </HubspotProvider>
      )}
      <Typography
        variant="h1"
        color="white"
        sx={{
          "@media (max-width: 768px)": {
            fontSize: 48,
            lineHeight: 1,
          },
        }}
      >
        Global energy data
      </Typography>
      <Typography
        variant="body"
        color="white"
        sx={{
            fontSize: {xs: "16px", md: "21px"},
            lineHeight: {xs:"22px", md:"34px"},
            marginTop: "24px",
            maxWidth: "760px"
        }}
      >
        Compiled data on world energy consumption, coming from different energy sources (World energy consumption data per/by fuel). Analyze how the use of fossil fuels and alternative energies have evolved globally and by country.
      </Typography>
      <Stack
        gap="10px"
        direction={{
          xs: "column",
          lg: "row",
        }}
      >
        <Container
          sx={{
            margin: { xs: "50px 0 20px 0", md: "32px 0" },
            minWidth: { md: "762px" },
          }}
        >
          <BarChart id="veg2Q0n6klwFSbIArncg8Y" title="World share of energy consumption, 2021" style={{ height: "192px" }} />
        </Container>
        <Container
          sx={{
            margin: { xs: "50px 0 20px 0", md: "32px 0" },
          }}
        >
          <RadarChart id="22YCl02Iv7xToZ2f0ZQaqD" title="Energy consumption, 1990 vs 2021" style={{ height: "192px" }} />
        </Container>
      </Stack>
      <Container gap="24px">
        <Typography
          variant="h6"
          style={{
            color: "white",
            opacity: 0.8,
          }}
        >
          Worldwide statistics, Change in energy consumption, 1990-2021
        </Typography>
        <Grid>
          {cards.map((cardInfos, index) => {
            return <Card key={index} cardInfos={cardInfos} />;
          })}
        </Grid>
      </Container>
      <div style={{ height: "1000px" }} />
    </Stack>
  );
}

export default EnergySources;
