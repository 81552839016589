export default function largeNumFormat(num, locale, dec = 2) {
  // demo is in English, locked the locale
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: dec,
    maximumFractionDigits: dec,
  });

  if (num >= 1e12) {
    return formatter.format((num / 1e12).toFixed(dec)) + 'Tri';
  } else if (num >= 1e9) {
    return formatter.format((num / 1e9).toFixed(dec)) + 'B';
  } else if (num >= 1e6) {
    return formatter.format((num / 1e6).toFixed(dec)) + 'M';
  }
  else if (num >= 1e3) {
    return formatter.format((num / 1e3).toFixed(dec)) + 'K';
  } else {
    return formatter.format(num.toFixed(dec));
  }
}